import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState, type FunctionComponent } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useMirrorLoading } from 'shared-loading-indicator'
import { api } from '../utilities/api'
import { Button } from './Button'
import { useGetRecaptchaToken } from './contexts/RecaptchaContextProvider'
import { useRegionListData } from './contexts/RegionListContext'
import { useTranslate } from './contexts/TranslationsContextProvider'
import { FlashMessage } from './FlashMessage'
import { LabelWithField } from './LabelWithField'
import styles from './TaxForm.module.sass'

export type FormDataProps = {
	email: string
	name: string
	userId: string
	vatId: string
	address1: string
	address2?: string
	city: string
	postalCode: string
	country: string
	state: string
	dates: [Date, Date]
	submitError: boolean | string
}

const TIME_TO_DISPLAY_MESSAGE = 3000 // in MS

export const TaxForm: FunctionComponent = () => {
	const { submit, getUser } = api.taxForm
	const mutation = submit.useMutation()
	const user = getUser.useQuery()
	const regionList = useRegionListData()
	const translations = useTranslate()
	const [isResetable, setIsResetable] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const getRecaptchaToken = useGetRecaptchaToken()

	const successMessage = translations('Form.successMessage')
	const errorMessage = translations('Form.errorMessage')

	const countryOptions = useMemo(() => {
		return regionList.map((region) => ({
			value: region.code,
			label: region.label,
		}))
	}, [regionList])

	const form = useForm<FormDataProps>({
		defaultValues: {
			email: undefined,
			name: undefined,
			userId: undefined,
			vatId: undefined,
			address1: undefined,
			address2: undefined,
			city: undefined,
			postalCode: undefined,
			country: undefined,
			state: undefined,
			dates: undefined,
		},
	})

	const onSubmit: SubmitHandler<FormDataProps> = useCallback(
		async (data, event) => {
			event?.preventDefault()
			const recaptchaToken = await getRecaptchaToken()

			if (recaptchaToken === null) {
				console.error('Recaptcha token is null')
				return
			}

			if (!data.dates[0] || !data.dates[1]) {
				console.warn(`Missing dates:
							Start date: ${data.dates[0]}
							End date: ${data.dates[1]}
						`)
				setIsSubmitted(true)
				return
			}

			// GTM event
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({ event: 'form_send' })

			setIsResetable(true)
			mutation.mutate({
				recaptcha: recaptchaToken,
				userId: data.userId,
				email: data.email,
				name: data.name,
				vatId: data.vatId,
				address1: data.address1,
				address2: data.address2,
				city: data.city,
				postalCode: data.postalCode,
				country: data.country,
				state: data.state,
				dateFrom: data.dates[0],
				dateTo: data.dates[1],
			})
		},
		[getRecaptchaToken, mutation],
	)

	// reset form after successful submission
	useEffect(() => {
		if (isResetable && mutation.data?.mailStatus) {
			form.reset()

			setIsResetable(false)
		}
	}, [isResetable, form, mutation.data?.mailStatus, mutation.status, setIsResetable])

	useMirrorLoading(mutation.status === 'loading' || user.status === 'loading')

	// set submitted state for displaying success/error message
	useEffect(() => {
		if (mutation.status === 'success' || mutation.status === 'error') {
			setIsSubmitted(true)
			const timeout = setTimeout(() => {
				setIsSubmitted(false)
			}, TIME_TO_DISPLAY_MESSAGE)

			return () => {
				clearTimeout(timeout)
			}
		}
	}, [mutation.status])

	// set initial values for form from user data
	useEffect(() => {
		const userData = user.data?.getUser

		if (userData) {
			form.setValue('userId', userData.id)
			form.setValue('email', userData.email)
			userData.name && form.setValue('name', userData.name)
			userData.region && form.setValue('country', userData.region.code)
		}
	}, [form, user])

	return (
		<FormProvider {...form}>
			<form className={styles.wrapper} onSubmit={form.handleSubmit(onSubmit)}>
				<div className={clsx(styles.message, isSubmitted && styles.is_visible)}>
					<FlashMessage
						type={mutation.data?.mailStatus ? 'success' : 'error'}
						message={mutation.data?.mailStatus ? successMessage : errorMessage}
					/>
				</div>
				<div className={styles.fields}>
					<div className={styles.item}>
						<LabelWithField
							fieldType="default"
							labelText={translations('TaxForm.userId.label')}
							type="text"
							name="userId"
							required
						/>
					</div>
					<div className={styles.item}>
						<LabelWithField
							fieldType="default"
							name="email"
							type="email"
							required
							labelText={translations('TaxForm.email.label')}
							autoComplete="email"
						/>
					</div>
					<div className={styles.item}>
						<LabelWithField
							fieldType="default"
							name="name"
							type="text"
							required
							labelText={translations('TaxForm.name.label')}
							autoComplete="name"
						/>
					</div>
					<div className={styles.item}>
						<LabelWithField
							fieldType="default"
							name="vatId"
							type="text"
							required
							labelText={translations('TaxForm.vatId.label')}
						/>
					</div>
					<div className={styles.item}>
						<LabelWithField
							fieldType="default"
							name="address1"
							type="text"
							required
							labelText={translations('TaxForm.address1.label')}
							autoComplete="address-line1"
						/>
					</div>
					<div className={styles.item}>
						<LabelWithField
							fieldType="default"
							name="address2"
							type="text"
							labelText={translations('TaxForm.address2.label')}
							autoComplete="address-line2"
						/>
					</div>
					<div className={styles.item}>
						<LabelWithField
							fieldType="default"
							name="city"
							type="text"
							required
							labelText={translations('TaxForm.city.label')}
							autoComplete="address-level2"
						/>
					</div>
					<div className={styles.item}>
						<LabelWithField
							fieldType="default"
							name="postalCode"
							type="text"
							required
							labelText={translations('TaxForm.postalCode.label')}
							autoComplete="postal-code"
						/>
					</div>
					<div className={styles.item}>
						<LabelWithField
							fieldType="select"
							name="country"
							labelText={translations('TaxForm.country.label')}
							options={countryOptions}
							required
							isSearchable={false}
						/>
					</div>
					<div className={styles.item}>
						<LabelWithField
							fieldType="default"
							name="state"
							type="text"
							required
							labelText={translations('TaxForm.state.label')}
							autoComplete="address-level1"
						/>
					</div>
					<div className={styles.item}>
						<LabelWithField
							fieldType="rangeDate"
							labelText={translations('TaxForm.date.label')}
							name="dates"
							required
						/>
					</div>
				</div>
				<div className={styles.submit}>
					<Button type="submit">{translations('Form.submit')}</Button>
				</div>
			</form>
		</FormProvider>
	)
}
